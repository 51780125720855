$(function() {

  $('#nav-toggle').on('click', function() {
    $('body').toggleClass('cay-nav-open');
  });

  window.showSnackbar = function (message, style) {
    var snackbar = $('#snackbar')[0];
    snackbar.className = 'mdl-js-snackbar mdl-snackbar cay-snackbar--' + (style || 'default');
    snackbar.MaterialSnackbar.showSnackbar({
      message: message,
      timeout: 6000
    });
  }

});
