$(function() {

  $('[data-table-url]').each(function() {
    var $table = $(this);
    var $content = $('.cay-data-table__content', this);
    var $spinner = $('.cay-data-table__spinner', this);

    loadData();

    $table.on('click', '[data-new-url]', function() {
      onClickNew($(this));
    });

    $table.on('click', '[data-edit-url]', function() {
      onClickEdit($(this));
    });

    function loadData() {
      $spinner.show().addClass('is-active');
      $content.load($table.data('table-url'), function() {
        $spinner.hide().removeClass('is-active');
        $('table', $content).tablesorter({sortList:[[0, 0]]});
      });
    }

    function onClickNew($el) {
      var title = $el.data('new-title') || 'New';
      var url = $el.data('new-url');
      var $dialog = createDialog(title, url);
      $dialog[0].showModal();
    }

    function onClickEdit($el) {
      var title = $el.data('edit-title') || 'Edit';
      var url = $el.data('edit-url');
      var $dialog = createDialog(title, url);
      $dialog[0].showModal();
    }

    function createDialog(title, dialogContentUrl) {
      var $dialog = $(
        '<dialog class="mdl-dialog cay-data-table-dialog">' +
          '<h4 class="mdl-dialog__title">' + title + '</h4>' +
          '<div class="mdl-dialog__content">' +
            '<div class="cay-data-table-dialog__spinner mdl-spinner mdl-js-spinner is-active"></div>' +
          '</div>' +
          '<div class="mdl-dialog__actions">' +
            '<button type="button" class="mdl-button mdl-button--raised mdl-button--primary" data-submit-dialog>Save</button>' +
            '<button type="button" class="mdl-button" data-close-dialog>Cancel</button>' +
          '</div>' +
        '</dialog>'
      );
      var $spinner = $('.mdl-spinner', $dialog);
      var $submitButton = $('[data-submit-dialog]', $dialog);

      $('.mdl-dialog__content', $dialog).load(dialogContentUrl, onLoadSuccess);
      $submitButton.on('click', function() {
        submitDialog();
      });
      $('[data-close-dialog]', $dialog).on('click', function() {
        closeDialog();
      });

      $dialog.appendTo('body');
      componentHandler.upgradeElement($spinner[0]);
      return $dialog;

      function onLoadSuccess() {
        // upgrade dialog dynamic mdl content
        $('[class*=mdl-js-]', $dialog).each(function() {
          componentHandler.upgradeElement(this);
        });
        // upgrade form to submit via ajax
        $('form', $dialog).on('submit', function() {
          submitDialog();
          return false;
        });
      }

      function submitDialog() {
        var $form = $('form', $dialog);
        var url = $form.attr('action') || dialogContentUrl;
        $submitButton.attr('disabled', 'disabled');
        $.ajax({
          type: 'POST',
          url: url,
          processData: false,
          contentType: false,
          data: new FormData($form[0]),
          dataType: 'json'
        }).done(onSubmitSuccess).fail(onSubmitError).always(function() {
          $submitButton.removeAttr('disabled');
        });
      }

      function onSubmitSuccess(data) {
        if ($.isEmptyObject(data.error)) {
          closeDialog();
          loadData();
        } else {
          $.each(data.error, function(attribute, errors) {
            var $input = $('[name="' + attribute + '"]');
            var $field = $input.closest('.mdl-textfield');
            var $error = $('.mdl-textfield__error', $field);

            $field.addClass('is-invalid');
            $error.text(errors[0]);
          });
        }
      }

      function onSubmitError(err) {
        console.error(err);
        showSnackbar('An unexpected error occurred.', 'error');
      }

      function closeDialog() {
        $dialog[0].close();
        $dialog[0].remove();
      }
    }

  });

});
